@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .m#{$abbrev}-#{$size} { 
        max-#{$prop}: $length !important; 
    }
  }
}


@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  .btn#{$infix}-inline-block {
    @include media-breakpoint-up($breakpoint) {
      display: inline-block;
      width: auto;
    }
  }
}