.btn{
    transition: .2s ease all;
    font-weight: 500;
    &-back{
        display: inline-flex;
        align-items: center;
        color:$nogosh-grey2;
        transition: .2s ease all;
        &:hover, &:active, &:focus{
            text-decoration: none !important;
            color: $nogosh-blue;
        }

    }
}