$font-family-base: 'Roboto', Arial, Helvetica, sans-serif ;
$blue: $nogosh-blue;  
$green: $nogosh-green;
$red: $nogosh-red;
$yellow: $nogosh-yellow;


$body-bg:                   $nogosh-light3;
$body-color:                $nogosh-blue;

$secondary: $nogosh-grey2;

$theme-colors: (
    "light-blue": $nogosh-blue2,
    "nogosh-grey1": $nogosh-grey1,
    "nogosh-grey2": $nogosh-grey2,
    "nogosh-grey3": $nogosh-grey3
);


$card-border-radius:6px;

$dropdown-item-padding-x: 1rem;

$sizes: (
    25: 25%,
    40: 40%,
    50: 50%,
    75: 75%,
    100: 100%
);