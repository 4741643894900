.card-event{
    box-shadow: 0 3px 7px rgba(0,0,0,.03);
    &:not(.card-event-0){
        margin-top: $grid-gutter-width/3*2;
        @include media-breakpoint-up(md){
            margin-top: $grid-gutter-width;
        }

    }

    .btn-xs{
        @extend .btn-sm;
        @extend .px-3;
        //font-weight:700;
    }

    .card-body{
        >.row{
            min-height:60px;
        }
        .btn{
            position:relative;
            z-index:2;
        }
    }
    .card-header{
        &, .col{
            position: static;
        }
    }

    .match-name{
        &:after{
            content: "";
            position: absolute;
            z-index: 1;
            width: 100%; height: 100%;
            left: 0; top: 0;
        }
    }

    .event-list-chat-btn{
        position: relative;
        z-index:2;
        display: inline-block;
        transition: .2s ease opacity;
        opacity: 1;

        &:hover {
          opacity: .8;
        }

        &:before{
            content: "";
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            position: absolute;
            left: -10px; top: -10px;
            z-index: 1;
            border-radius: 100%;
        }
    }

    .event-list-button{
        @extend .col-auto;
        @extend .mt-2;
        @extend .mb-2;
        +app-dialog{
            @extend .flex-sm-fill;
            @include media-breakpoint-down(xs){
                width:100%;
            }
        }
    }

    .badge{
        &-status{
            font-weight: 700;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            @extend .px-3;
            @include media-breakpoint-up(md){
                min-width: 100px;
            }
            + .dropdown{
               >.dropdown-toggle{
                   float:right;
                    opacity: .5;
                    transition: .2s ease all;
                    @extend .pl-3;
                    @extend .pr-1;
                    @extend .mr-n2;
                    line-height:1;

                    &:hover, &[aria-expanded="true"]{
                        opacity:1;
                    }
               }
            }
        }
    }   //  /.badge

    .confirmed{
        text-align: right;
        >*{
            display: block;
            &.ratio{
               font-weight: 700;
            }
            &.addon{
                color: $nogosh-grey3;
                font-size: 0.875rem;
            }
        }
    }   //  /.confirmed

    .cal{
        min-width: 34px;
        min-height: 34px;
        line-height: 1;
        font-weight: 500;
        @extend .d-flex;
        @extend .flex-column;
        align-items: center; justify-content: center;
        .month{
            font-size: 12px;
            text-transform: uppercase;
        }
        .date{

        }
    }   //  /.cal




}   //  /.card-event

.matches{
    >*{
       /*  @extend .animated;
        @extend .fadeIn;
        animation-delay: .5s; */
    }
}

.players-nickname{
    @include media-breakpoint-down(sm){
        .players-nickname-holder{
            position:absolute;
            max-width:100%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

   popover-container{
       .popover-content{
         .img-profile{
             img{
              border: 1px solid $border-color;
                animation-delay: .2s;
             }
             +.player-details{
                 margin-top: 10px;
             }
         }
         .player-details {

         }
       }
   }
}

.confirmed-by-manager{
    a{
        position:relative;
        z-index:0;
        &:before{
            content: "M";
            position:absolute;
            left:0;top:0; transform:translate(-50%, -50%);
            background: $nogosh-grey2; //$nogosh-red;
            color: #fff;
    font-size: 8px;
    border-radius: 100%;
    display: inline-flex;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    z-index: -1;
        }
    }
}

