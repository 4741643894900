html, body{
    height: 100%;
} 

app-root{
    height: 100%;
    min-width: 320px;
    @extend .d-flex;
    @extend .flex-column;
    >[_ngcontent-c0]{
        @extend .flex-fill;
        @extend .d-flex;
        @extend .flex-column;
    }
}