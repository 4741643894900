
.modal {
    &-component{
        .modal-dialog{
            z-index:1;
            .modal-header{
                @extend .pr-5;
            }
            .dialog__close-btn{
               transition:.2s ease all; 
               position: absolute;
               z-index: 1;
               top: 0; right: 0; 
               margin: $modal-header-padding;
            }
            .modal-footer{
                @extend .justify-content-center;
            }
        }
        .overlay{
            z-index:0;
            top: 0; left: 0; width:100vw; height: 100vh;
            position: fixed;
        }
    }

}