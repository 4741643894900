

@mixin firefox-only {
    @at-root {
      @-moz-document url-prefix() {
        & {
          @content;
        }
      }
    }
  } 

.no-caret{
   &.dropdown-toggle:after{
       content: none;
   }
}
.no-underline{
    &, &:hover, &:active, &:focus{
        text-decoration: none !important;
    }
}
$cursors: auto default none contex-menu help pointer progress wait cell crosshair text vertical-text alias copy move no-drop not-allowed e-resize n-resize ne-resize nw-resize s-resize se-resize sw-resize w-resize ew-resize ns-resize nesw-resize nwse-resize col-resize row-resize all-scroll zoom-in zoom-out grab grabbing;
.cursor{
    @each $cursor in $cursors{
        &-#{$cursor}{
            cursor: #{$cursor};
        }
    }
}
.icon{
    &-rotatable{
        &[aria-expanded]{
            i, .fas, .far{
                transition: .2s ease all;
            }
            
        }
        $angles : 45 90 135 180 225 270 315;
        @each $var in  $angles {
          &-#{$var}{
            &[aria-expanded="true"]{
              i{
                transform: rotate(#{$var}deg);
              }
            }  
          }  
        }
    }
}

.row{
    &-half{
        margin-left:-$grid-gutter-width/4;
        margin-right:-$grid-gutter-width/4;
        >*{
            padding-left:$grid-gutter-width/4 !important;
            padding-right:$grid-gutter-width/4 !important;  
        }
    }
}

.container{
    @include media-breakpoint-down(md){
        width: 100%;
        max-width: 100% !important;
    }
}
.hide{
    &-empty{
        &:empty{
            display: none;  
        }
    }
}
.border{
    $bsides : top right bottom left;
    $widths: 2 3 4 5;
    @each $side in $bsides{
        
        &-#{$side}{        
            &-not-last{
                &:last-child{
                    border-#{$side}-color:transparent !important;
                }
            }
            &-not-empty{
                &:empty{
                    border-#{$side}-color:transparent !important;  
                }
            }
            @each $width in $widths{
                &-#{$width}{
                    border-#{$side}-width: #{$width}px !important;
                }
            }
        }
    }
    @each $width in $widths {
        &-#{$width}{
            border-style: solid;
            border-width: #{$width}px !important;
        }
    }    
}   //  /.border

.min{
    &w-0{
        min-width: 0;
    }
}
.avatar-team{
    min-height: 40px;
    min-width: 40px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $nogosh-light3;
    @extend .rounded-circle;
    img{width: 40px; height: 40px;}
    &-md{
        min-height: 54px;
        min-width: 54px;   
    }
    &-lg{
        @include media-breakpoint-up(sm){        
            min-height: 200px;
            min-width: 200px; 
        }  
    }
}

a{
    transition: .2s ease all;
    &.disabled{
        cursor: not-allowed;
        pointer-events: none;
        opacity: .6;
    }

}

.opacity{
    $i : 0;
    @while $i <= 1 {
        /* #{$i*100} */
        &-#{round($i*100)} {
            opacity: $i;
        }
        $i : $i + 0.01;
    }
}

.header-divider{
    @extend .col-12;
    @extend .mt-1;
    &:after{
        content:"";
        display: block;
        @extend .border-bottom;
        @extend .border-bottom-2;
    }
}

[role="main"]{
    @extend .animated;
    @extend .fadeIn;
}
header{
    h2{
        /* @include media-breakpoint-down(xs){
            text-align:center;
        } */
    }
}   //  /header

label{
    @extend .text-sm;
    font-weight: 500;
}

.d{
    &-empty-none{
        &:empty{
            display:none;
        }
    }
    &-expanded{
        @each $breakpoint in map-keys($grid-breakpoints) {
          @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);            
            @each $value in $displays {
              *[aria-expanded*="true"] &-true#{$infix}-#{$value} { display: $value !important; }
              *[aria-expanded*="false"] &-false#{$infix}-#{$value} { display: $value !important; }
            }
          }
        }
    }
}   //  /.d

.overflow{
    &-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.sebm-google-map-container {
    height: 370px;
  }


  .table{
      th, td{
          &.rotate{
                vertical-align: bottom;
            /* max-width: 1em;
            padding: 0 !important; */
            font-size: .8rem;
            line-height: 1.1;
                >spanOLD{
                display: inline-block;
                transform: rotate(270deg) translate(.5em, -1.5em);
                white-space: nowrap;
                text-align: left;
                vertical-align: middle;
                transform-origin: center bottom;
                height: 100%;
                width: 100px;                 
                }
            }
        &.player-name{
            max-width: 200px;
        }
      }
      &-fixed{
          table-layout: fixed;
      }
      

  }