$nogosh-blue:   #00254C;
$nogosh-blue2:  #1C9BD5;
$nogosh-grey1:  #5C6979;
$nogosh-grey2:  #A0A6AE;
$nogosh-grey3:  #838C96;
$nogosh-green:  #7ED321;
$nogosh-dark:   #222222;
$nogosh-light:  #EBEBEB;
$nogosh-light2: #CED6DE;
$nogosh-light3: #F4F4F4;
$nogosh-red:    #D32121;
$nogosh-yellow: #F1CA0F;

