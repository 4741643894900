xmessages{ 
   @extend .flex-fill; 
   @extend .d-flex;
   @extend .flex-column;
   //@extend .h-100;

   [role="main"]{
    @extend .flex-fill; 
   }
}
.scroll-wrapper{
    position:relative;
    z-index:0;
    /* &:before, &:after{
        content:"";
        position:absolute;
        top:0; left: $grid-gutter-width/2; width:calc(100% - #{$grid-gutter-width}); height:10px;
        background-color: rgba($body-bg, .7);
        //background: linear-gradient(0deg,rgba($body-bg, 0),rgba($body-bg, 1)); 
        z-index:1;
    } */
    &:after{
        top:auto; bottom:0; 
    }
}
.scroll-parent{
    overflow: auto;
    //max-height: 70vh;
    min-height:110px;
    /* @extend .d-flex;
    @extend .flex-column-reverse; */
    .search-results{
        /* min-height: auto;
        @media screen and (min--moz-device-pixel-ratio:0) {
            min-height: 0;
        } */
        >:first-child{
            margin-top:10px;
        }
        >:last-child{
            .border-bottom{
                border-bottom: 0 !important; 
            }
        }
    }

    .msgText{
       img{
           max-width: 100%;
       } 
    }
}   // /.scroll-parent

.msgTime{
    @include media-breakpoint-down(sm){
        font-size:.75rem;
    }
}

messages ~ app-footer{
    display: none;
}