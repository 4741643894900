app-nav-bar{
  //@extend .sticky-top;
}
.navbar-main{
    .navbar-brand{
      font-weight: 700;  
    } 
    .nav-item.dropdown .dropdown-menu{
      box-shadow: $dropdown-box-shadow;
    } 
    .nav-divider{
      @include media-breakpoint-down(xs){
        margin: .2rem 0;
        border-top: $border-width solid $border-color;
      }
    } 
}   //  /.navbar-main

.nav-link{
    transition:.3s ease all;
    padding-top: $nav-link-padding-y/2;
    padding-bottom: $nav-link-padding-y/2;
    font-weight: 500;    
}

.active{
  &.dropdown-item,
  &.btn-primary{
    box-shadow:none !important;
    background-color: lighten($nogosh-blue, 10%) !important;
  }
}