.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 700;
}

$font-weights: 100 200 300 400 500 600 700 800 900;
.font-weight{
    @each $value in $font-weights {
        &-#{$value}{
            font-weight: $value;
        }
    }  
}
.line-height{
    $i : 0;
    @while $i <= 2 {
        /* #{$i*100} */
        &-#{round($i*10)} {
            line-height: $i;
        }
        $i : $i + 0.1;
    }
}


$rheadings: (
    h1 $h1-font-size,
    h2 $h2-font-size,
    h3 $h3-font-size,
    h4 $h4-font-size,
    h5 $h5-font-size,
    h6 $h6-font-size,
);
.text{
    &-sm{
        font-size: 0.875rem;
    }
    &-md{
        font-size: 1rem;
    }
    
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
          $infix: breakpoint-infix($breakpoint, $grid-breakpoints);            
          @each $value in $rheadings {
            &#{$infix}-#{nth($value, 1)} { 
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: $headings-line-height;
                @include font-size(nth($value, 2));
            }
          }
        }
      }
}


